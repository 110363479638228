@import "../../variables.scss";

    .messagesBg {
        background: linear-gradient(to bottom, 
            rgba(255, 255, 255, 0.863) 0%,
            rgba(255, 255, 255, 0.932) 100%), 
            url("../../assets/ghosts-2.jpg") repeat;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        justify-content: flex-end;
        overflow: hidden;
        position: relative;
        z-index: 1;
        
        .messagesCont {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: transform 0.5s;
            margin: 0 auto;
            width: 100%;
        }
    }