@import "./variables.scss";

.App {
  align-items: center;
  background: url("./assets/ghosts.jpg") repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  
  .messengerContainer {
    box-shadow: 0px 0px 15px -2px rgb(141, 141, 141);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100vw;

    h1 {
      align-items: center;
      background: $primary-color;
      color: $primary-text;
      display: flex;
      font-size: 24px;
      font-weight: 700;
      height: 50px;
      justify-content: center;
      width: 100%;
      z-index: 10;
    }
  }
}

@media screen and (min-width: 768px) {
  .App {
    padding: 15px;

    .messengerContainer {
      border-radius: 4px;
      width: 80vw;
    }
  }
}

@media screen and (min-width: 1200px) {
  .App {
    .messengerContainer {
      width: 70vw;
    }
  }
}