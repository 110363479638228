@import "../../variables.scss";

.messageCont {
    background-color: $secondary-color;
    border-radius: 4px 4px 0 4px;
    margin: 10px;
    max-width: 80%;
    padding: 5px 10px 8px;
    position: relative;
    transition: transform 0.5s;
    width: fit-content;
    z-index: 5;

    p {
        color: $secondary-text;
    }

    .username {
        background: $tertiary-light;
        border-radius: 0 0 4px 4px;
        bottom: -16px;
        font-size: 12px;
        left: 0;
        padding: 1px 5px;
        position: absolute;
        white-space: nowrap;
        width: fit-content;
    }
}

.messageCont.leftPos {
    background-color: $tertiary-color;
    border-radius: 4px 4px 4px 0;
    margin: 10px auto 23px 10px;
}

.messageCont.disappear {
    filter: blur(100px);
    opacity: 0;
    transform: scale(10.0);
    transition: all 2s;
}

img {
    animation-delay: 0s, 5s;
    animation-duration: 5s, 2s;
    animation-fill-mode: forwards;
    animation-name: ghostAppear, ghostCarry;
    bottom: 0;
    height: 120px;
    object-fit: contain;
    position: absolute;
    right: 0;
    width: 120px;
    z-index: 11;
}

img.leftPos{
    left: 0;
}

@keyframes ghostAppear {
    0% {
        opacity: 0;
        transform: translateY(150px);
    }
    25% {
        transform: translateY(70px);
    }
    50% {
        transform: translateY(100px) rotate(20deg);
    }
    80% {
        opacity: 0.3;
        transform: translateY(50px) rotate(0deg);
    }
    84% {
        transform: translateY(50px) rotate(15deg);
    }
    90% {
        transform: translateY(50px) rotate(-15deg);
    }
    98% {
        transform: translateY(50px) rotate(15deg);
    }
    100% {
        opacity: 0.4;
        transform: translateY(50px) rotate(15deg);
    }
}

@keyframes ghostCarry {
    from {
        opacity: 0.5;
        transform: translateY(50px) rotate(15deg);
    }
    to {
        filter: blur(10px);
        opacity: 0;
        transform: translateY(-100vh) rotate(-1000deg);
    }
}
