$primary-color: rgb(191, 224, 3);
$primary-light: rgb(224, 228, 0);
$primary-dark: rgb(181, 206, 43);
$secondary-color: rgb(91, 2, 194);
$secondary-light: rgb(190, 161, 223);
$tertiary-color: rgb(80, 61, 63);
$tertiary-light: rgb(169, 169, 169);
$red-dark: rgb(232, 64, 64);
$red-light: rgb(234, 91, 91);
$grey-bg: rgb(240, 240, 240);
$grey-border: rgb(231, 231, 231);
$grey-border-dark: rgb(214, 214, 214);
$primary-text: rgb(49, 49, 49);
$secondary-text: rgb(255, 255, 255);