@import "../../variables.scss";

.micBtn {
    width: 35px;

    img {
        animation: none;
        height: 15px;
        position: relative;
        top: 1px;
        width: 15px;
    }
}

.micBtnListening {
    background-color: $red-light;
    animation: listening 2s infinite ease-in-out;
    transition: 0.3s;

    &:hover {
        background-color: $red-dark;
    }

    img {
        filter:  brightness(0) invert(1);
    }
}

@keyframes listening {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.05);
    }
}