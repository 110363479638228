@import "../../variables.scss";

.loginContainer {
    background: rgba(0, 0, 0, 0.315);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2000;

    .loginBox {
        background: $grey-bg;
        border-radius: 4px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        left: calc(50vw - 150px);
        padding: 16px;
        padding-bottom: 1px;
        position: fixed;
        top: calc(50vh - 70px);
        width: 300px;

        h3, p {
            padding-bottom: 4px;
            text-align: center;
        }

        p {
            border-bottom: 2px $grey-border solid;
            padding-bottom: 8px;
        }

        .noBorder {
            border: none;
        }
    }
}