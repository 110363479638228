@import "./variables.scss";

.inputCont {
    background-color: $grey-bg;
    border-top: 2px $grey-border solid;
    border-bottom: 2px $grey-border solid;
    display: flex;
    padding: 10px;
    z-index: 10;
}

button {
    background-color: $primary-color;
    border: none;
    border-radius: 4px;
    color: $primary-text;
    cursor: pointer;
    font-weight: 700;
    margin-left: 10px;
    padding: 7px;
    transform:translateY(0px);
    transition: background-color 50ms;
    width: 70px;
    
    &:hover {
        background-color: $primary-light;
    }
    
    &:active {
        background-color: $primary-dark;
        transform:translateY(1px);
    }
}

input {
    border: $grey-border solid 2px;
    border-radius: 4px;
    flex-grow: 1;
    outline: none;
    padding: 0 10px;
    transition: 0.2s;

    &:hover {
        border: $grey-border-dark solid 2px;
    }

    &:focus-visible,
    &:active {
        border: $secondary-light solid 2px;
    }
}